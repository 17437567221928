.writeup-text:first-child {
    margin-top: 0;
}

.writeup-text:last-child {
    margin-bottom: 0;
}

.cca-logo {
    width: 61.8%;
}