.landingpage-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    column-gap: 0.618em;
    row-gap: 0.618em;
    padding: 0.618em;
    box-sizing: border-box;
    background-color: white;
}

.landingpage-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: top;
}