.content-container {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.content-video {
    width: 61.8vw;
    padding: 1em;
    box-sizing: border-box;
}

.content-text {
    width: 38.2vw;
    padding: 1em;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .content-video {
        width: 100vw;
    }

    .content-text {
        width: 100vw;
    }
}